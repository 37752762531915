import $ from 'jquery';
import pageTransitions from './components/pageTransitions';
import Gallery from './components/gallery';
import Filter from './components/filter';
import Notice from './components/notice';
import ProjectItem from './components/projectItem';
import ImageSlider from './components/imageSlider';
import Accordion from './components/accordion';
import Nav from './components/nav';
import HeroGallery from './components/heroGallery';
import GallerySlider from './components/gallerySlider';

export default class App {
  constructor() {
    this.components = {
      gallery: Gallery,
      filter: Filter,
      notice: Notice,
      projectItem: ProjectItem,
      imageSlider: ImageSlider,
      heroGallery: HeroGallery,
      accordion: Accordion,
      gallerySlider: GallerySlider,
    };
  }

  init(container) {
    const components = this.components;

    $(container).find('[data-component]').each(function() {
      const node = this;
      $(node).data('component').split(/\s+/).forEach((componentName) => {
        const component = components[componentName];
        if (component) {
          new component(node);
        }
      });
    });
  }
}

$(function() {
  const navButton = document.getElementById('nav-button');
  if (navButton) {
    new Nav(navButton);
  }
  // Instantiate Barba.js
  pageTransitions();
});

