import anime from 'animejs/lib/anime.es.js';

export default ($targets, duration, from, to, easing = 'easeInOutCubic') => {
  const targets = [];

  $targets.each(function() {
    this.style.transform = `translateY(${from}px)`;
    targets.push(this);
  });

  const translateY = `${to}px`;
  const anim = anime.timeline({
    easing: easing,
    duration,
  });
  
  anim.add({
    targets,
    translateY,
  });

  return anim.finished;
};
