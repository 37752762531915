import $ from 'jquery';

class Filter {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.$toggle = $node.find('.filter__toggle');

    this.eventhandlers();
  }

  eventhandlers() {
    this.$toggle.on('click', () => {
      if (this.$node.hasClass('is-open')) {
        this.$node.removeClass('is-open');
      } else {
        this.$node.addClass('is-open');
      }
    });
  }
}

export default Filter;
