import $ from 'jquery';
import barba from '@barba/core';
import { gsap } from 'gsap';
import slide from '../transitions/slide';
import fade from '../transitions/fade';
import App from '../app';

export default function () {
  barba.hooks.beforeLeave(({current}) => {
    // Remove old class from body
    if (current.namespace !== '') {
      barba.wrapper.classList.remove(current.namespace);
    }
  });
    
  barba.hooks.beforeEnter(({next}) => {
    const app = new App();
    const backgroundColor = $(next.container).data('style');
    app.init(next.container);
    // Add Class of next page to body
    barba.wrapper.classList.add(next.namespace);
    $(barba.wrapper).attr('style', backgroundColor);
    barba.wrapper.classList.remove('nav-is-open');
    $(window).scrollTop(0);
  });

  barba.init({
    timeout: 10000,
    preventRunning: true,
    prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),
    transitions: [{
      name: 'opacity-transition',
      leave(data) {
        return Promise.all([
          gsap.to(data.current.container, {opacity: 0, duration: 0.6})
        ]); 
      },
      enter(data) {
        return Promise.all([
          gsap.from(data.next.container, {opacity: 0, duration: 0.6})
        ]);
      }
    }, {
      name: 'slide-transition',
      from: {
        // define a custom rule based on the trigger class
        custom: ({ trigger }) => {
          return trigger.classList && trigger.classList.contains('slide-transtion');
        },
      },
      leave: ({ current }) => {
        barba.wrapper.classList.add('is-animating');
        const $currentContainer = $(current.container);
        const $container = $currentContainer.children('.container');
        const $indexContent = $currentContainer.children('.index-content');
        const translateY = $indexContent.offset().top - $(window).scrollTop();

        $currentContainer.find('.heading').animate({
          opacity: 0
        }, 600);

        return Promise.all([
          fade($container, 200, 1, 0),
          slide($indexContent, 800, 0, -translateY)
        ]);
      },
      enter: () => {
        barba.wrapper.classList.remove('is-animating');
      }
    }, {
      name: 'slide-to-project',
      from: {
        // define a custom rule based on the trigger class
        custom: ({ trigger }) => {
          return trigger.classList && trigger.classList.contains('slide-to-project');
        },
      },
      leave: ({ current, trigger }) => {
        barba.wrapper.classList.add('is-animating');
        const $currentContainer = $(current.container);
        const $container = $currentContainer.children('.container');
        const $indexContent = $currentContainer.children('.index-content');
        const translateY = $indexContent.offset().top - $(window).scrollTop();
        const $trigger = $(trigger);
        const $item = $trigger.parent('li');
        const itemTranslateY = $item.offset().top - $trigger.parents('ul.index').offset().top;
        
        $currentContainer.find('.heading').animate({
          opacity: 0
        }, 600);

        $currentContainer.find('.project-thumbnails').fadeOut(400);

        $item.siblings('li').css({
          opacity: 0
        });

        return Promise.all([
          fade($container, 200, 1, 0),
          slide($item, 800, 0, -itemTranslateY),
          slide($indexContent, 800, 0, -translateY)
        ]);
      },
      enter: ({ next }) => {
        barba.wrapper.classList.remove('is-animating');

        return Promise.all([
          fade($(next.container).find('.page-content'), 400, 0, 1),
        ]);
      }
    }]
  });
}
