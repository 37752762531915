import $ from 'jquery';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js';

class Gallery {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.$items = $node.find('.pswp-item');
    this.slides = [];
    this.type = $node.data('type');
    this.container = this.$node.parents('#content').find('.pswp')[0];
    this.gallery = null;

    this.buildSlidesArray();
    this.eventHandlers();
  }

  eventHandlers() {
    this.$items.on('click', (e) => {
      const $this = $(e.currentTarget);
      const index = $this.index();
      const backgroundColour = $this.parents('.pswp-gallery').data('background-colour');
      const $backgroundEl = $(this.container).find('.pswp__bg');

      $backgroundEl.css({
        backgroundColor: backgroundColour
      });

      if (this.type === 'light') {
        $(this.container).removeClass('dark'); 
      } else {
        $(this.container).addClass(this.type); 
      }

      this.open(index);
    });
  }

  open(index) {
    const options = {
      index: index,
      history: false,
      fullscreenEl: false,
      shareEl: false,
      counterEl: false,
      zoomEl: false,
      captionEl: false,
      barsSize: {top: 30, bottom: 30},
      maxSpreadZoom: 1,
      clickToCloseNonZoomable: true,
      getDoubleTapZoom: (isMouseClick, item) => {
        return item.initialZoomLevel;
      },
      getThumbBoundsFn: (index) => {
        const thumbnail = this.$items.eq(index).find('img')[0], // find thumbnail
            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
            rect = thumbnail.getBoundingClientRect(); 

        return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
      }
    };

    this.gallery = new PhotoSwipe(this.container, PhotoSwipeUI_Default, this.slides, options);
    this.gallery.init();
  }

  buildSlidesArray() {
    this.$items.each((index, item) => {
      const $this = $(item);
      const $picture = $this.find('picture');
      const $img = $this.find('img');
      const size = $picture.data('size').split('x');
      const slide = {
        src: $picture.data('gallery-img-src'),
        msrc: $img.attr('data-src'),
        w: size[0],
        h: size[1],
        title: $img.attr('alt')
      };

      this.slides.push(slide);
    });
  }
}

export default Gallery;
