import anime from 'animejs/lib/anime.es.js';

export default ($targets, duration, from, to, easing = 'easeInOutSine') => {
  const targets = $targets[0];

  $targets.css({opacity: from});

  const opacity = to;
  const anim = anime.timeline({
    easing: easing,
    duration,
  });
  
  anim.add({
    targets,
    opacity,
  });

  return anim.finished;
};
