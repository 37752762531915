import $ from 'jquery';
import Flickity from 'flickity';

class ImageSlider {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.hasUI = $node.find('.image-slider__img').length > 1;
    
    this.slider = new Flickity(node, {
      wrapAround: true,
      lazyLoad: 3,
      pageDots: this.hasUI,
      prevNextButtons: this.hasUI,
      arrowShape: { 
        x0: 10,
        x1: 60, y1: 50,
        x2: 70, y2: 40,
        x3: 30
      }
    });
  }
}

export default ImageSlider;
