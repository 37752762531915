import $ from 'jquery';
import Flickity from 'flickity-fade';

class HeroGallery {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    
    this.slider = new Flickity(node, {
      wrapAround: true,
      lazyLoad: 3,
      prevNextButtons: false,
      autoPlay: 3000,
      setGallerySize: false,
      fade: true,
      pauseAutoPlayOnHover: false,
    });
  }
}

export default HeroGallery;
