import $ from 'jquery';
import Flickity from 'flickity';

class GallerySlider {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.hasUI = $node.find('.gallery-slider__img').length > 1;
    console.log(node);
    
    this.slider = new Flickity(node, {
      wrapAround: true,
      lazyLoad: 2,
      pageDots: false,
      draggable: false,
      setGallerySize: false,
      prevNextButtons: this.hasUI,
      arrowShape: { 
        x0: 10,
        x1: 60, y1: 50,
        x2: 70, y2: 40,
        x3: 30
      }
    });

    console.log(this.slider);
  }
}

export default GallerySlider;
