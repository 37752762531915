import $ from 'jquery';

class Nav {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.$body = $('body');

    this.eventhandlers();
  }

  eventhandlers() {
    this.$node.on('click', () => {
      if (this.$body.hasClass('nav-is-open')) {
        this.$body.removeClass('nav-is-open');
      } else {
        this.$body.addClass('nav-is-open');
      }
    });
  }
}

export default Nav;