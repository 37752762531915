import $ from 'jquery';

class Accordion {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.$items = $node.find('.accordion__toggle');

    this.eventhandlers();
  }

  eventhandlers() {
    this.$items.on('click', function() {
      const $this = $(this);
      const $container = $this.parents('li');

      if ($container.hasClass('is-open')) {
        $container.removeClass('is-open');
      } else {
        $container.addClass('is-open').siblings('li').removeClass('is-open');
      }
    });
  }
}

export default Accordion;
