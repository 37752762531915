import $ from 'jquery';

class ProjectItem {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.index = this.$node.data('id');
    this.$anchor = $node.children('a');
    this.$img = $('.project-thumbnails').children(`picture[data-id="${this.index}"]`);

    this.eventHandlers();
  }

  eventHandlers() {
    this.$anchor
      .on('mouseenter', () => {
        if (this.$img) {
          this.$img.css({
            opacity: 1
          });
        }
      })
      .on('mouseleave', () => {
        if (this.$img) {
          this.$img.css({
            opacity: 0
          });
        }
      });
  }
}

export default ProjectItem;
